import {
 ReactNode,
 createContext,
 useCallback,
 useContext,
 useState,
} from "react";
import { emptyForm } from "../mock";
import { arrayMove, deleteForm } from "../utils";
import { FormContextType } from "./FormContextType";
import { FormComponentType, FormType } from "../models/form.data.type";

interface FormProviderProps {
 children: ReactNode;
}

const FormContext = createContext<FormContextType>({
 form: emptyForm,
 setForm: () => {},
 editMode: false,
 setEditMode: () => {},
 updateFormName: () => {},
 updateFormDescription: () => {},
 updateFormComponentTitleAndDescription: () => {},
 updateInputComponent: () => {},
 updateMultiComponent: () => {},
 updateDatePickerComponent: () => {},
 updateFormComponentType: () => {},
 switchFormComponentRequiredState: () => {},
 moveFormComponent: () => {},
 addFormComponent: () => {},
 deleteFormComponent: () => {},
 DeleteForm: () => {},
 addFormComponents: () => {},
 getFormLength: () => 0,
});

export const FormContextProvider: React.FC<FormProviderProps> = ({
 children,
}) => {
 const [form, setForm] = useState<FormType>(emptyForm);
 const [editMode, setEditMode] = useState<boolean>(false);

 const getFormLength = useCallback(() => form.components.length, [form]);

 const updateFormName = useCallback(
  (name: string) => {
   setForm((prev) => ({ ...prev, name }));
  },
  [setForm]
 );

 const updateFormDescription = useCallback(
  (description: string) => {
   setForm((prev) => ({ ...prev, description }));
  },
  [setForm]
 );

 const updateFormComponent = useCallback(
  (component: FormComponentType, index: number) => {
   setForm((prev) => {
    if (prev.components[index]) prev.components[index] = { ...component };
    return { ...prev };
   });
  },
  [setForm]
 );

 const updateFormComponentTitleAndDescription = useCallback(
  (
   { title, description }: { title: string; description: string },
   index: number
  ) => {
   setForm((prev) => {
    if (prev.components[index])
     prev.components[index] = { ...prev.components[index], title, description };
    return { ...prev };
   });
  },
  [setForm]
 );

 const updateInputComponent = useCallback(
  (component: FormComponentType, index: number) => {
   updateFormComponent(
    {
     ...component,
     type: "text",
     title: component.title,
     description: component.description,
     format: component.format,
     required: component.required,
    },
    index
   );
  },
  [updateFormComponent]
 );

 // const updateEmailComponent = useCallback(
 //  ({ title, description, format, required }, index) => {
 //   updateFormComponent(
 //    { type: "email", title, description, format, required },
 //    index
 //   );
 //  },
 //  [updateFormComponent]
 // );

 //  const updatePhoneComponent = useCallback(
 //   ({ title, description, format, required }, index) => {
 //    updateFormComponent(
 //     { type: "phone", title, description, format, required },
 //     index
 //    );
 //   },
 //   [updateFormComponent]
 //  );
 //  const updateSsnComponent = useCallback(
 //   ({ title, description, format, required }, index) => {
 //    updateFormComponent(
 //     { type: "ssn", title, description, format, required },
 //     index
 //    );
 //   },
 //   [updateFormComponent]
 //  );

 const updateMultiComponent = useCallback(
  (component: FormComponentType, index: number) => {
   if (!["select", "radio", "checkbox"].includes(component.type))
    component.type = "select";
   updateFormComponent(
    {
     type: component.type,
     title: component.title,
     description: component.description,
     options: component.options,
     required: component.required,
    },
    index
   );
  },
  [updateFormComponent]
 );

 const updateDatePickerComponent = useCallback(
  (component: FormComponentType, index: number) => {
   updateFormComponent(
    {
     type: "datetime",
     title: component.title,
     description: component.description,
     format: component.format,
     mode: component.mode,
     required: component.required,
    },
    index
   );
  },
  [updateFormComponent]
 );

 const updateFormComponentType = useCallback(
  (component: FormComponentType, index: number) => {
   switch (component.type) {
    case "text":
    default:
     updateInputComponent(component, index);
     break;

    // case "email":
    //  updateEmailComponent(component, index);
    //  break;

    // case "phone":
    //  updatePhoneComponent(component, index);
    //  break;

    // case "ssn":
    //  updateSsnComponent(component, index);
    //  break;

    case "select":
    case "radio":
    case "checkbox":
     updateMultiComponent(component, index);
     break;

    case "datetime":
     updateDatePickerComponent(component, index);
     break;

    case "title":
    case "description":
     updateFormComponent(
      {
       ...component,
       type: component.type,
       title: component.title || "",
       description: component.description || "",
      },
      index
     );
     break;
   }
  },
  [
   updateInputComponent,
   updateMultiComponent,
   updateDatePickerComponent,
   updateFormComponent,
  ]
 );

 //  const updateFormComponentValue = useCallback(
 //   (value: string, index: number) => {
 //    setForm((prev) => {
 //     if (prev.components[index])
 //      prev.components[index] = {
 //       ...prev.components[index],
 //       value: editMode ? null : value,
 //      };
 //     return { ...prev };
 //    });
 //   },
 //   [editMode]
 //  );

 const switchFormComponentRequiredState = useCallback(
  (state: boolean, index: number) => {
   setForm((prev) => {
    if (prev.components[index]) prev.components[index].required = state;
    return { ...prev };
   });
  },
  []
 );

 const moveFormComponent = useCallback(
  (direction: "up" | "down", index: number) => {
   setForm((prev) => {
    prev.components = [
     ...arrayMove(
      prev.components,
      index,
      direction === "up" ? index - 1 : index + 1
     ),
    ];
    return { ...prev };
   });
  },
  [setForm]
 );

 const addFormComponent = useCallback(
  (index: number) => {
   setForm((prev) => {
    prev.components.push({
     type: "text",
     title: "İsimsiz soru",
     format: "short",
     required: false,
    });
    prev.components = [...arrayMove(prev.components, -1, index + 1)];
    return { ...prev };
   });
  },
  [setForm]
 );

 const deleteFormComponent = useCallback(
  (index: number) => {
   setForm((prev) => {
    prev.components[index] = { ...prev.components[index], _id: "-1" };
    prev.components = [
     ...prev.components.filter((component) => component._id !== "-1"),
    ];
    return { ...prev };
   });
  },
  [setForm]
 );

 const addFormComponents = useCallback(
  (questions: FormComponentType[]) => {
   setForm((prev) => {
    Array.prototype.unshift.apply(prev.components, questions);
    return { ...prev };
   });
  },
  [setForm]
 );

 //  const cleanForm = useCallback(() => {
 //   form.components.forEach(updateFormComponent);
 //  }, [form, updateFormComponent]);

 const DeleteForm = async (name: string) => {
  let temp = await deleteForm(name);
  if (temp) {
   window.location.reload();
  } else {
   alert("Form silinemedi, yetki hatası!");
  }
 };

 return (
  <FormContext.Provider
   value={{
    form,
    setForm,
    getFormLength,
    editMode,
    setEditMode,
    updateFormName,
    updateFormDescription,
    updateFormComponentTitleAndDescription,
    updateInputComponent,
    updateMultiComponent,
    updateDatePickerComponent,
    updateFormComponentType,
    // updateFormComponentValue,
    switchFormComponentRequiredState,
    moveFormComponent,
    addFormComponent,
    deleteFormComponent,
    // cleanForm,
    addFormComponents,
    DeleteForm,
   }}
  >
   {children}
  </FormContext.Provider>
 );
};

export const useForm = () => {
 return useContext(FormContext);
};
