import { Button, Checkbox, ConfigProvider, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";

import { useForm } from "../../../contexts";
import { cvQuestions, eventQuestions } from "../../../models/quickQuestions";
import { Box } from "../../../sharedComponent";

export const QuickQuestions = () => {
 const { addFormComponents, form } = useForm();
 const [tempQuestions, setTempQuestions] = useState("");

 useEffect(() => {
  if (tempQuestions === "cv") {
   addFormComponents(cvQuestions);
  } else if (tempQuestions === "event") {
   addFormComponents(eventQuestions);
  }
 }, [tempQuestions]);

 const options = [
  { label: "CV formatı için gerekli olan 9 soru", value: "cv" },
  // { label: 'Etkinlik sistemi', value: 'event' }
 ];

 if (tempQuestions.length) {
  return <></>;
 }
 return (
  <Box>
   <div className="flex text-black items-center flex-col justify-center">
    <Typography.Title
     style={{ color: "white" }}
     className="m-0 p-0"
     //  align={"left"}
     level={4}
    >
     Kullanacağınız sisteme göre soru şablonunu seçiniz.
    </Typography.Title>
    <div className="w-full flex">
     <Select
      onChange={(e) => {
       setTempQuestions(e);
      }}
      placeholder="Şablon seçiniz"
      options={options}
      className="w-full"
     />
    </div>
   </div>
  </Box>
 );
};
