import { useForm } from "../contexts";
import * as Icon from "@ant-design/icons";
import { Button, Select, Switch } from "antd";
import { FormComponentType } from "../models/form.data.type";

export const FormComponentSettings = ({
 component,
 index,
}: {
 component: FormComponentType;
 index: number;
}) => {
 const {
  getFormLength,
  updateFormComponentType,
  switchFormComponentRequiredState,
  moveFormComponent,
  addFormComponent,
  deleteFormComponent,
 } = useForm();

 const handleSwitchRequiredState = (state: boolean) =>
  switchFormComponentRequiredState(state, index);
 const handleMoveUp = () => moveFormComponent("up", index);
 const handleMoveDown = () => moveFormComponent("down", index);
 const handleTypeChange = (value: any) =>
  updateFormComponentType({ ...component, ...JSON.parse(value) }, index);
 const handleDelete = () => deleteFormComponent(index);
 const handleAdd = () => addFormComponent(index);

 return (
  <div className="flex mb-1 flex-col sm:flex-row gap-2">
   <div className="flex gap-x-1 my-1 sm:my-0">
    {!["title", "description"].includes(component.type) && (
     <Switch
      onChange={handleSwitchRequiredState}
      checked={component.required}
      className="self-center"
      checkedChildren="Zorunlu"
      unCheckedChildren="Serbest"
     />
    )}
    <Button onClick={handleAdd} type="default" icon={<Icon.PlusOutlined />} />
    {getFormLength() > 1 && (
     <Button
      onClick={handleDelete}
      className="text-red-500"
      type="default"
      icon={<Icon.DeleteOutlined />}
     />
    )}

    {index !== 0 && (
     <Button
      onClick={handleMoveUp}
      type="default"
      icon={<Icon.CaretUpFilled />}
     />
    )}
    {index !== getFormLength() - 1 && (
     <Button
      onClick={handleMoveDown}
      type="default"
      icon={<Icon.CaretDownFilled />}
     />
    )}
   </div>

   <Select
    className="max-w-56 min-w-32 mb-1 sm:mb-0"
    value={JSON.stringify({
     type: component.type,
     format: component.format,
     mode: component.mode,
    })}
    onChange={handleTypeChange}
    options={options}
   />
  </div>
 );
};

const options = [
 {
  label: "Bilgilendirme",
  options: [
   {
    label: (
     <>
      <Icon.FontColorsOutlined /> Başlık
     </>
    ),
    value: '{"type":"title"}',
   },
   // {
   //   label: (
   //     <>
   //       <ItalicOutlined /> Açıklama
   //     </>
   //   ),
   //   value: '{"type":"description"}'
   // }
  ],
 },
 {
  label: "Yazı",
  options: [
   {
    label: (
     <>
      <Icon.PicCenterOutlined /> Kısa Yanıt
     </>
    ),
    value: '{"type":"text","format":"short"}',
   },
   {
    label: (
     <>
      <Icon.MailOutlined /> Email
     </>
    ),
    value: '{"type":"email","format":"short"}',
   },
   {
    label: (
     <>
      <Icon.PhoneOutlined className="scale-[-1]" /> Telefon Numarası
     </>
    ),
    value: '{"type":"phone","format":"short"}',
   },
   {
    label: (
     <>
      <Icon.UserOutlined className="" /> TC Kimlik
     </>
    ),
    value: '{"type":"ssn","format":"short"}',
   },
   {
    label: (
     <>
      <Icon.AlignLeftOutlined /> Paragraf
     </>
    ),
    value: '{"type":"text","format":"long"}',
   },
  ],
 },
 {
  label: "Çok Seçenekli",
  options: [
   {
    label: (
     <>
      <Icon.DownCircleOutlined /> Açılır Menü
     </>
    ),
    value: '{"type":"select"}',
   },
   {
    label: (
     <>
      <Icon.CheckCircleOutlined /> Çoktan Seçmeli
     </>
    ),
    value: '{"type":"radio"}',
   },
   {
    label: (
     <>
      <Icon.CheckSquareOutlined /> Onay Kutuları
     </>
    ),
    value: '{"type":"checkbox"}',
   },
  ],
 },
 {
  label: "Tarih",
  options: [
   {
    label: (
     <>
      <Icon.CalendarOutlined /> Tarih
     </>
    ),
    value: '{"type":"datetime","format":"date","mode":"single"}',
   },
   {
    label: (
     <>
      <Icon.ClockCircleOutlined /> Tarih Saat
     </>
    ),
    value: '{"type":"datetime","format":"datetime","mode":"single"}',
   },
   // {
   //   label: (
   //     <>
   //       <Icon.CalendarOutlined /> Tarih Aralığı
   //     </>
   //   ),
   //   value: '{"type":"datetime","format":"date","mode":"range"}'
   // },
   // {
   //   label: (
   //     <>
   //       <Icon.ClockCircleOutlined /> Tarih Saat Aralığı
   //     </>
   //   ),
   //   value: '{"type":"datetime","format":"datetime","mode":"range"}'
   // }
  ],
 },
];
