import { Button, Popconfirm } from "antd";
import * as Icon from "@ant-design/icons";
import { useForm } from "../../../contexts";
import { useNavigate } from "react-router-dom";
import { FormType } from "../../../models/form.data.type";

export const FormCard = ({ data }: { data: FormType }) => {
 const navigate = useNavigate();
 const { DeleteForm } = useForm();

 return (
  <div className="w-[95vw] md:w-[40vw] lg:w-[350px] hover:scale-[1.015] duration-300 bg-white/15 rounded-lg p-4 flex flex-col justify-between border-2 border-solid border-white/30 gap-y-8">
   <div className="space-y-2">
    <h1 className="font-bold text-[24px] line-clamp-2">{data.name}</h1>
    <p className="opacity-80 line-clamp-2">{data.description}</p>
   </div>
   <div className="flex justify-end items-center h-[40px] ">
    <Button
     icon={<Icon.FileAddOutlined />}
     className=" text-white bg-transparent mr-2 "
     onClick={() => {
      navigate(`/submit?form=${data.link}`);
     }}
    />
    <Button
     icon={<Icon.InfoCircleOutlined />}
     className=" text-white bg-transparent mr-2 "
     onClick={() => {
      navigate("/response?form=" + data.link);
     }}
    />
    <Popconfirm
     onConfirm={() => {
      DeleteForm(data.link || "");
     }}
     title="Formu sil"
     description="Formu silmek istediğinizden emin misiniz?"
     okText="Evet"
     cancelText="Hayır"
    >
     <Button
      icon={<Icon.DeleteOutlined />}
      danger
      className=" text-red-600 bg-transparent"
     />
    </Popconfirm>
   </div>
  </div>
 );
};
