import { Checkbox, ConfigProvider, Form, Radio, Select } from "antd";
import { Typography } from "antd";
import { FormComponentType } from "../models/form.data.type";
import { useForm } from "../contexts";

export const FormMulti = ({
 component,
 index,
}: {
 component: FormComponentType;
 index: number;
}) => {
 const {
  editMode,
  updateMultiComponent,
  // updateFormComponentValue
 } = useForm();

 const handleOptionsChange = (options: string[]) => {
  updateMultiComponent({ ...component, options }, index);
 };

 //  const handleChange = (value: string) => {
 //   updateFormComponentValue(value, index);
 //  };

 let Component;
 switch (component.type) {
  case "select":
   Component = !editMode ? (
    <Form.Item
     label={component.title}
     extra={component.description}
     name={component._id}
     rules={
      component.required
       ? [{ required: true, message: "Bu alanı doldurmak zorunludur!" }]
       : []
     }
    >
     <Select
      className="w-full hover:bg-none focus:bg-white"
      placeholder={component.title}
      options={component?.options?.map((option) => ({
       value: option,
       label: option,
      }))}
      allowClear
     />
    </Form.Item>
   ) : (
    <Select
     className="w-full hover:bg-none focus:bg-white"
     placeholder={component.title}
     options={component?.options?.map((option) => ({
      value: option,
      label: option,
     }))}
     allowClear
    />
   );
   break;
  case "radio":
   Component = !editMode ? (
    <Form.Item
     label={component.title}
     extra={component.description}
     name={component._id}
     rules={
      component.required
       ? [{ required: true, message: "Bu alanı doldurmak zorunludur!" }]
       : []
     }
    >
     <Radio.Group>
      {component?.options?.map((option, index) => (
       <Radio
        style={{ color: "white" }}
        className="m-1"
        value={option}
        key={index}
       >
        {option}
       </Radio>
      ))}
     </Radio.Group>
    </Form.Item>
   ) : (
    <Radio.Group>
     {component?.options?.map((option, index) => (
      <Radio
       style={{ color: "white" }}
       className="m-1"
       value={option}
       key={index}
      >
       {option}
      </Radio>
     ))}
    </Radio.Group>
   );
   break;
  case "checkbox":
  default:
   Component = (
    <ConfigProvider
     theme={{
      token: {
       colorText: "white",
      },
     }}
    >
     {!editMode ? (
      <Form.Item
       label={component.title}
       extra={component.description}
       name={component._id}
       rules={
        component.required
         ? [{ required: true, message: "Bu alanı doldurmak zorunludur!" }]
         : []
       }
      >
       <Checkbox.Group
        className="!text-white"
        style={{ color: "white" }}
        options={component.options}
       />
      </Form.Item>
     ) : (
      <Checkbox.Group
       className="!text-white"
       style={{ color: "white" }}
       options={component.options}
      />
     )}
    </ConfigProvider>
   );
   break;
 }

 return editMode ? (
  <div>
   <Typography.Text className="p-1">Seçenekleri düzenleyin:</Typography.Text>
   <Select
    className="w-full mb-1"
    mode="tags"
    placeholder="Seçenekleri Düzenleyin"
    value={component.options}
    onChange={handleOptionsChange}
    allowClear
   />
   <div className="flex flex-col">
    <Typography.Text className="p-1">Önizleme:</Typography.Text>
    {Component}
   </div>
  </div>
 ) : (
  Component
 );
};
