import { Form, Input } from "antd";
import { FormComponentType } from "../models/form.data.type";

export const FormInput = ({
 component,
 index,
 type,
}: {
 component: FormComponentType;
 index: number;
 type: "phone" | "email" | "ssn" | "";
}) => {
 let editMode = false;

 const inputTypes = {
  email: "Lütfen geçerli bir E-mail yazınız!",
  phone: "Lütfen geçerli bir telefon numarası giriniz!",
 } as any;

 const options = {
  placeholder: !editMode
   ? component.title
   : component.format === "long"
   ? "Paragraf"
   : "Kısa Yanıt",
  className: "hover:bg-white focus:bg-white",
 };
 return !editMode ? (
  <Form.Item
   //  index={index}
   label={component.title}
   extra={component.description}
   name={component._id}
   rules={
    component.required
     ? [
        { required: true, message: "Bu alanı doldurmak zorunludur!" },
        //  { type: type, message: inputTypes[type] },
        //  type === "ssn" && {
        //   min: 11,
        //   message: "Lütfen geçerli bir TC Kimlik Numarası giriniz!",
        //  },
       ]
     : []
   }
  >
   {component.format === "long" ? (
    <Input.TextArea {...options} />
   ) : (
    <Input {...options} />
   )}
  </Form.Item>
 ) : component.format === "long" ? (
  <Input.TextArea {...options} />
 ) : (
  <Input {...options} />
 );
};
