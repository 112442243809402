/*
 * * Written by Mehmet Alperen Yedik <mehmetalperenyedik@gmail.com>, January 2024
 * * Even if this has added something to the code, don't remove it
 */
import { axiosForFormBuilder } from ".";
import { axiosForAuth } from "./axiosForAuth";

// In case of no session in AuthMs, redirect the application to this url
// Don't forget to change the last part of the url "/export" according to your application
const LoginUrl = "https://login.kapsul.org.tr/form";

// Check session in AuthMs
const checkForAuth = async () => {
 try {
  const res = await axiosForAuth.get(`/user/check`);
  return res.data.result;
 } catch (error) {
  console.error(error);
  return false;
 }
};

// Check session in form-builder-ms
export const checkForForm = async () => {
 try {
  const res = await axiosForFormBuilder.get(`/auth/check`);
  return res.data.result;
 } catch (error) {
  console.error(error);
  return false;
 }
};

// Request a token from AuthMs and log in to form-builder-ms
const login = async () => {
 try {
  const tokenResponse = await axiosForAuth.get("/user/token", {
   params: {
    moduleName: "form-builder",
   },
  });
  const token = tokenResponse.data.token;

  if (token) {
   const response = await axiosForFormBuilder.post("/auth/login", {
    token: token,
   });

   return response.data.result;
  }
 } catch (error) {
  console.error("Take Token and Login Ms error:", error);
  return false;
 }
};

// Logout for AuthMs
const logoutForAuth = async () => {
 try {
  const res = await axiosForAuth.get(`/user/logout`);
  if (res.data.result) return res.data.result;
 } catch (error) {
  console.error(error);
  return false;
 }
};

// Logout for form-builder-ms
const logoutForForm = async () => {
 try {
  const res = await axiosForFormBuilder.get(`/auth/logout`);
  if (res.data.result) return res.data.result;
 } catch (error) {
  console.error(error);
  return false;
 }
};

// Login function
export const Login = async () => {
 let temp = await login();
 if (temp) return true;
 else alert("Hata! Mikroservise giriş yapılamadı.");
};

// Function that controls the session
export const Check = async () => {
 // Check for AuthMs
 let authCheck = await checkForAuth();
 if (authCheck) {
  // Check for form-builder-ms
  if (!(await checkForForm())) return Login();
  else return true;
 }
 // Go to login.kapsulteknoloji.org/form
 else window.location.href = LoginUrl;
};

// Logout function from AuthMs and form-builder-ms
export const Logout = async () => {
 let auth = await logoutForAuth();
 let form = await logoutForForm();

 if (auth && form) {
  window.location.href = LoginUrl;
 } else {
  alert("Hata! Çıkış yapılamadı.");
 }
};
