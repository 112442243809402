export const ReadQueries = (url: string) => {
 let data = {} as any;

 // URL'i '?' işaretine göre ayırarak query string kısmını elde ediyoruz
 let _sep = url.split("?");
 if (_sep.length > 1) {
  let queryString = _sep[1];

  // Query stringi '&' işaretine göre ayırarak her bir parametreyi elde ediyoruz
  let queries = queryString.split("&");

  queries.forEach((query: string) => {
   let [key, value] = query.split("=");
   if (key && value) {
    data[key] = value;
   }
  });
 }

 return data;
};

export function ReadQuery(queryString: string) {
 if (queryString.charAt(0) === "?") queryString = queryString.substring(1);

 let params = {} as any;

 if (queryString) {
  let pairs = queryString.split("&");

  for (let pair of pairs) {
   let keyValue = pair.split("=");
   let key = decodeURIComponent(keyValue[0]);
   let value = decodeURIComponent(keyValue[1]);
   params[key] = value;
  }
 }

 return params;
}
