import { Typography } from "antd";

import { Box, FormDatePicker, FormInput, FormMulti } from ".";
import { FormComponentType } from "../models/form.data.type";

export const SubmitFormComponent = ({
 component,
 index,
}: {
 component: FormComponentType;
 index: number;
}) => {
 let Component;
 switch (component.type) {
  case "text":
   Component = <FormInput component={component} index={index} type="" />;
   break;

  case "email":
   Component = <FormInput component={component} index={index} type="email" />;
   break;

  case "phone":
   Component = <FormInput component={component} index={index} type="phone" />;
   break;

  case "select":
   Component = <FormMulti component={component} index={index} />;
   break;

  case "radio":
   Component = <FormMulti component={component} index={index} />;
   break;

  case "checkbox":
   Component = <FormMulti component={component} index={index} />;
   break;

  case "datetime":
   Component = <FormDatePicker component={component} index={index} />;
   break;

  default:
   break;
 }

 return (
  <Box>
   {/* {component.type} */}
   {/* <div
        className={`flex md:flex-row flex-col w-full md:items-end items-start  ${
          component.type === 'title' ? '-mb-2 justify-center' : component.type === 'description' ? 'justify-end' : 'justify-between'
        }`}
      >
        {component.title && component.type !== 'description' && (
          <Typography.Title style={{ color: 'white' }} className="m-0 p-0 " align={component.type === 'title' ? 'center' : 'left'} level={4}>
            {component.required ? '*' : ''}
            {component.title}
          </Typography.Title>
        )}
      </div> */}

   {Component}

   {/* {component.description && component.type !== 'title' && (
        <div className={component.type !== 'description' ? 'mt-2' : ''}>
          <Typography.Text style={{ color: 'white' }} className="m-0 p-0" align="left" level={2}>
            {component.description}
          </Typography.Text>
        </div>
      )} */}
  </Box>
 );
};
