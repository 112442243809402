import { axiosForFormBuilder } from ".";
import { FormAnswersType } from "../models/form.data.type";

export const createResponse = async (form: FormAnswersType) => {
 try {
  const res = await axiosForFormBuilder.post(
   `forms/${form.userId}/responses/`,
   { answers: form.answers }
  );

  return true;
  // return false
 } catch (error) {
  console.error(error);
  return false;
 }
};
