import { ConfigProvider, DatePicker, Form } from "antd";
import { FormComponentType } from "../models/form.data.type";

export const FormDatePicker = ({
 component,
 index,
}: {
 component: FormComponentType;
 index: number;
}) => {
 let editMode = false;
 //  const { updateFormComponentValue, editMode } = useForm();

 //  const handleInputChange = (value: any) =>
 //   updateFormComponentValue(value, index);

 const options = {
  showTime: component.format === "datetime" ? true : false,
  // onChange: handleInputChange,
  // value: component.value,
  className: "hover:bg-white focus:bg-white w-full",
  // placement: "topLeft",
 };

 let responsive = window.innerWidth < 640 ? true : false;

 let componentView =
  responsive === true
   ? component.format === "date"
     ? {
        cellWidth: 28,
        cellHeight: 21,
        timeColumnHeight: 12,
        timeColumnWidth: 28,
        textHeight: 30,
       }
     : {
        cellWidth: 19,
        cellHeight: 18,
        timeColumnHeight: 19,
        timeColumnWidth: 22,
        textHeight: 30,
       }
   : {};

 return component.mode === "single" ? (
  <ConfigProvider
   theme={{
    components: {
     DatePicker: componentView,
    },
   }}
  >
   {!editMode ? (
    <Form.Item
     label={component.title}
     extra={component.description}
     name={component._id}
     rules={
      component.required
       ? [{ required: true, message: "Bu alanı doldurmak zorunludur!" }]
       : []
     }
    >
     <DatePicker
      popupClassName={`${responsive ? "text-[12px]" : ""}`}
      {...options}
     />
    </Form.Item>
   ) : (
    <DatePicker
     popupClassName={`${responsive ? "text-[12px]" : ""}`}
     {...options}
    />
   )}
  </ConfigProvider>
 ) : !editMode ? (
  <Form.Item
   label={component.title}
   extra={component.description}
   name={component._id}
   rules={
    component.required
     ? [{ required: true, message: "Bu alanı doldurmak zorunludur!" }]
     : []
   }
  >
   <DatePicker.RangePicker {...options} />
  </Form.Item>
 ) : (
  <DatePicker.RangePicker {...options} />
 );
};
