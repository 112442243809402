import { Logout } from "../utils/authService";
import { LogoutOutlined } from "@ant-design/icons";
import { ConfigProvider, FloatButton, Popconfirm } from "antd";

export const LogoutButton = () => {
 return (
  <ConfigProvider
   theme={{
    token: {
     colorPrimary: "#F74557",
    },
   }}
  >
   <Popconfirm
    title="Çıkış yap"
    description="Çıkış yapmak istediğinden emin misin?"
    onConfirm={() => Logout()}
    okText="Evet"
    cancelText="Hayır"
    placement="leftTop"
   >
    <FloatButton
     shape="circle"
     style={{ right: 50, backgroundColor: "red" }}
     icon={<LogoutOutlined />}
     type="primary"
    />
   </Popconfirm>
  </ConfigProvider>
 );
};
