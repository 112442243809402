import { UseGet } from "../../utils";
import { useLocation } from "react-router-dom";
import { FloatButtons } from "../../sharedComponent";
import { ReadQuery } from "../../helper/SearchQuery";
import { ResponsesTableContainers } from "./containers/ResponsesTableContainers";
import { FormType } from "../../models/form.data.type";

export const FormResponse = () => {
 const query = ReadQuery(useLocation().search);
 const { data, loading, error } = UseGet(`/forms/${query.form}`);

 return (
  <>
   <FloatButtons />
   <div className=" w-full flex flex-col items-center ">
    {loading ? (
     "Yükleniyor..."
    ) : error ? (
     "Aradığınız form veya yanıt bulunamadı."
    ) : (
     <>
      <h1 className="font-bold text-[40px]">{data.name}</h1>
      <ResponsesTableContainers form={data} />
     </>
    )}
   </div>
  </>
 );
};

const tempForm: FormType = {
 _id: "654a349e652f48378a6924b0",
 link: "aselsan-cv",
 name: "Aselsan CV",
 description: "test",
 components: [
  {
   title: "İsim",
   type: "text",
   required: true,
   format: "short",
   options: [],
   _id: "654a349e652f48378a6924b1",
  },
  {
   title: "Soyisim",
   type: "text",
   required: true,
   format: "short",
   options: [],
   _id: "654a349e652f48378a6924b2",
  },
  {
   title: "Cinsiyet",
   type: "radio",
   required: true,
   options: ["Erkek", "Kadın"],
   _id: "654a349e652f48378a6924b3",
  },
  {
   title: "Doğum Tarihi",
   type: "datetime",
   required: true,
   format: "date",
   mode: "single",
   options: [],
   _id: "654a349e652f48378a6924b4",
  },
  {
   title: "Eğitim durumunuz (okul/bölüm/sınıf)",
   type: "text",
   required: true,
   format: "short",
   options: [],
   _id: "654a349e652f48378a6924b5",
  },
  {
   title: "Kendinizi tanıtın",
   type: "text",
   required: false,
   format: "short",
   options: [],
   _id: "654a349e652f48378a6924b6",
  },
 ],
 isPublished: true,
 isDeleted: false,
 deletedAt: null,
 createdAt: "2023-11-07T12:59:10.800Z",
 updatedAt: "2023-11-07T12:59:10.800Z",
 __v: 0,
};
