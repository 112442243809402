import { FormType } from "../../../models/form.data.type";
import { UseGet } from "../../../utils";
import { FormCard } from "../components/FormCard";

export const FormContainers = () => {
 const { loading, error, data } = UseGet("/forms/all");

 return (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
   {loading
    ? "Yükleniyor"
    : error
    ? "Hata oluştu"
    : data.map((form: FormType) => {
       return <FormCard key={form._id} data={form} />;
      })}
  </div>
 );
};

const tempForms: FormType[] = [
 {
  _id: "654a349e652f48378a6924b0",
  link: "aselsan-cv",
  name: "Aselsan CV",
  description: "test",
  components: [
   {
    title: "İsim",
    type: "text",
    required: true,
    format: "short",
    options: [],
    _id: "654a349e652f48378a6924b1",
   },
   {
    title: "Soyisim",
    type: "text",
    required: true,
    format: "short",
    options: [],
    _id: "654a349e652f48378a6924b2",
   },
   {
    title: "Cinsiyet",
    type: "radio",
    required: true,
    options: ["Erkek", "Kadın"],
    _id: "654a349e652f48378a6924b3",
   },
   {
    title: "Doğum Tarihi",
    type: "datetime",
    required: true,
    format: "date",
    mode: "single",
    options: [],
    _id: "654a349e652f48378a6924b4",
   },
   {
    title: "Eğitim durumunuz (okul/bölüm/sınıf)",
    type: "text",
    required: true,
    format: "short",
    options: [],
    _id: "654a349e652f48378a6924b5",
   },
   {
    title: "Kendinizi tanıtın",
    type: "text",
    required: false,
    format: "short",
    options: [],
    _id: "654a349e652f48378a6924b6",
   },
  ],
  isPublished: true,
  isDeleted: false,
  deletedAt: null,
  createdAt: "2023-11-07T12:59:10.800Z",
  updatedAt: "2023-11-07T12:59:10.800Z",
  __v: 0,
 },
 {
  _id: "6644a6d32b75d4ede0e984cf",
  link: "bize-ulasin",
  name: "Bize Ulaşın",
  description:
   "Kapsül Teknoloji Platformundan gelen 'Bize Ulaşın' formu cevapları",
  components: [
   {
    title: "name",
    type: "text",
    required: false,
    format: "short",
    options: [],
    _id: "6644a6d32b75d4ede0e984d0",
   },
   {
    title: "phone",
    type: "text",
    required: false,
    format: "short",
    options: [],
    _id: "6644a6d32b75d4ede0e984d1",
   },
   {
    title: "email",
    type: "text",
    required: false,
    format: "short",
    options: [],
    _id: "6644a6d32b75d4ede0e984d2",
   },
   {
    title: "subject",
    type: "text",
    required: false,
    format: "short",
    options: [],
    _id: "6644a6d32b75d4ede0e984d3",
   },
   {
    title: "content",
    type: "text",
    required: false,
    format: "long",
    options: [],
    _id: "6644a6d32b75d4ede0e984d4",
   },
  ],
  isPublished: true,
  isDeleted: false,
  deletedAt: null,
  createdAt: "2024-05-15T12:13:07.600Z",
  updatedAt: "2024-05-15T12:13:07.600Z",
  __v: 0,
 },
 {
  _id: "664dc8c75f9e1c7b67c2bba8",
  link: "kapsul-teknoloji-platformu-is-basvuru-formu",
  name: "Kapsül Teknoloji Platformu İş Başvuru Formu",
  description:
   "Lütfen formu eksiksiz ve doğru şekilde doldurunuz. Bu bilgiler, başvurunuzu değerlendirmemize yardımcı olacaktır. Teşekkür ederiz.",
  components: [
   {
    title: "İsim",
    type: "text",
    required: true,
    format: "short",
    options: [],
    _id: "664dc8c75f9e1c7b67c2bba9",
   },
   {
    title: "Soyisim",
    type: "text",
    required: true,
    format: "short",
    options: [],
    _id: "664dc8c75f9e1c7b67c2bbaa",
   },
   {
    title: "Doğum Tarihi",
    type: "datetime",
    required: true,
    format: "date",
    mode: "single",
    options: [],
    _id: "664dc8c75f9e1c7b67c2bbab",
   },
   {
    title: "Cinsiyet",
    type: "radio",
    required: true,
    options: ["Erkek", "Kadın"],
    _id: "664dc8c75f9e1c7b67c2bbac",
   },
   {
    title: "Telefon Numarası",
    type: "text",
    description: "Başında sıfır olmadan giriniz.",
    required: true,
    format: "short",
    options: [],
    _id: "664dc8c75f9e1c7b67c2bbad",
   },
   {
    title: "Mail Adresi",
    type: "text",
    description:
     "Mail adresinizi yazarken lütfen karakterler arasında boşluk bırakmayınız.",
    required: true,
    format: "short",
    options: [],
    _id: "664dc8c75f9e1c7b67c2bbae",
   },
   {
    title: "Adres",
    type: "text",
    required: true,
    format: "long",
    options: [],
    _id: "664dc8c75f9e1c7b67c2bbaf",
   },
   {
    title: "Uyruk",
    type: "text",
    description: "Örneğin: Türk, English...",
    required: true,
    format: "short",
    options: [],
    _id: "664dc8c75f9e1c7b67c2bbb0",
   },
   {
    title: "Eğitim Durumunuz",
    type: "radio",
    description: "Eğitim durumunuzu işaretleyiniz.",
    required: true,
    options: ["Öğrenci", "Mezun"],
    _id: "664dc8c75f9e1c7b67c2bbb1",
   },
   {
    title: "Son Mezuniyet Dereceniz",
    type: "radio",
    description: "Son mezuniyet derecenizi işaretleyiniz.",
    required: true,
    options: ["Lise", "Lisans", "Yüksek Lisans", "Doktora"],
    _id: "664dc8c75f9e1c7b67c2bbb2",
   },
   {
    title: "Okul Bilgisi",
    type: "text",
    description:
     "Eğer mezunsanız son mezun olduğunuz okulu, öğrenciyseniz aktif olarak öğrenim gördüğünüz okulu yazınız.",
    required: true,
    format: "long",
    options: [],
    _id: "664dc8c75f9e1c7b67c2bbb3",
   },
   {
    title: "Bölüm Bilgisi",
    type: "text",
    description:
     "Eğer mezunsanız son mezun olduğunuz bölümü, öğrenciyseniz aktif olarak öğrenim gördüğünüz bölümü ve sınıfı yazınız",
    required: true,
    format: "long",
    options: [],
    _id: "664dc8c75f9e1c7b67c2bbb4",
   },
   {
    title: "Şu Anki İstihdam Durumu",
    type: "radio",
    description: "Şu anki istihdam durumunuzu işaretleyiniz.",
    required: true,
    options: ["Çalışan(Kamu)", "Çalışan(Özel Sektör)", "Çalışmayan", "Öğrenci"],
    _id: "664dc8c75f9e1c7b67c2bbb5",
   },
   {
    title: "Çalıştığınız Kurum ve Pozisyon",
    type: "text",
    description: "Son çalıştığınız kurum ve pozisyonu yazınız.",
    required: true,
    format: "long",
    options: [],
    _id: "664dc8c75f9e1c7b67c2bbb6",
   },
   {
    title: "Hangi Departmana Başvurmak İstiyorsunuz?",
    type: "checkbox",
    description: "Hangi departmana başvurmak istediğinizi işaretleyiniz.",
    required: true,
    options: [
     "Milli Teknoloji Hamlesi",
     "Borsa İstanbul Lab.",
     "İnsan Kaynakları",
     "Girişimcilik",
     "Engin Hub",
     "Akıllı Şehirler",
     "Akademi",
     "Kurumsal İletişim",
     "Proje",
     "Yazılım",
     "Diğer",
    ],
    _id: "664dc8c75f9e1c7b67c2bbb7",
   },
   {
    title: "Referanslar",
    type: "text",
    description:
     "Referanslarınızı çalıştığı kurum ve telefon numaraları ile birlikte yazınız.",
    required: false,
    format: "long",
    options: [],
    _id: "664dc8c75f9e1c7b67c2bbb8",
   },
  ],
  isPublished: true,
  isDeleted: false,
  deletedAt: null,
  createdAt: "2024-05-22T10:28:23.931Z",
  updatedAt: "2024-05-22T10:28:23.931Z",
  __v: 0,
 },
];
