export const Mail = (props) => (
 <svg
  stroke="currentColor"
  fill="none"
  strokeWidth="2"
  {...props}
  viewBox="0 0 24 24"
  height="200px"
  width="200px"
  xmlns="http://www.w3.org/2000/svg"
 >
  <rect width="20" height="16" x="2" y="4" rx="2"></rect>
  <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"></path>
 </svg>
);
