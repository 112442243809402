import "./App.css";
import Router from "./router/router";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider, FormContextProvider } from "./contexts";

function App() {

 return (
  <AuthContextProvider>
   <FormContextProvider>
    <BrowserRouter>
     <Router />
    </BrowserRouter>
   </FormContextProvider>
  </AuthContextProvider>
 );
}

export default App;
