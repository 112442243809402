import { FormComponentType } from "./form.data.type";

export const cvQuestions: FormComponentType[] = [
 {
  title: "İsim",
  type: "text",
  required: true,
  format: "short",
 },
 {
  title: "Soyisim",
  type: "text",
  required: true,
  format: "short",
 },
 {
  title: "Doğum Tarihi",
  type: "datetime",
  required: true,
  format: "date",
  mode: "single",
 },
 {
  title: "Cinsiyet",
  type: "radio",
  required: true,
  options: ["Erkek", "Kadın"],
 },
 {
  title: "Telefon Numarası",
  type: "text",
  description: "Başında sıfır olmadan giriniz.",
  required: true,
  format: "short",
 },
 {
  title: "Mail Adresi",
  type: "text",
  description:
   "Mail adresinizi yazarken lütfen karakterler arasında boşluk bırakmayınız.",
  required: true,
  format: "short",
 },
 {
  title: "Adres",
  type: "text",
  required: true,
  format: "long",
 },
 {
  title: "Uyruk",
  type: "text",
  description: "Örneğin: Türk, English...",
  required: true,
  format: "short",
 },
 {
  title: "Okul Bilgisi",
  type: "text",
  description:
   "Eğer mezunsanız son mezun olduğunuz okulu, öğrenciyseniz aktif olarak öğrenim gördüğünüz okulu yazınız.",
  required: true,
  format: "long",
 },
];

export const questionsTitle = [
 "İsim",
 "Soyisim",
 "Doğum Tarihi",
 "Cinsiyet",
 "Telefon Numarası",
 "Mail Adresi",
 "Eğitim Durumunuz",
 "Adres",
 "Uyruk",
];

export const eventQuestions: FormComponentType[] = [
 {
  title: "İsim",
  type: "text",
  required: true,
  format: "short",
 },
 {
  title: "Soyisim",
  type: "text",
  required: true,
  format: "short",
 },
 {
  title: "Doğum Tarihi",
  type: "datetime",
  required: true,
  format: "date",
  mode: "single",
 },
 {
  title: "TC Kimlik No",
  type: "ssn",
  required: true,
  format: "short",
 },
 {
  title: "Cinsiyet",
  type: "radio",
  required: true,
  options: ["Erkek", "Kadın"],
 },
 {
  title: "Telefon Numarası",
  type: "text",
  description: "Başında sıfır olmadan giriniz.",
  required: true,
  format: "short",
 },
 {
  title: "Email",
  type: "text",
  description: "E-mailinizi yazarken lütfen arasında boşluk bırakmayınız",
  required: true,
  format: "short",
 },
 {
  title: "TC kimlik numaranızı giriniz",
  type: "text",
  description: "Türkiye Cumhuriyeti Kimlik Numaranızı Yazınız.",
  required: true,
  format: "short",
 },
];
