import { FormType } from "../models/form.data.type";

export const emptyForm: FormType = {
 name: "Başlık giriniz",
 description: "Açıklama giriniz",
 components: [
  {
   type: "text",
   title: "Soru giriniz",
   format: "short",
   required: false,
  },
 ],
};
