import { useEffect, useState } from "react";
import { axiosForFormBuilder } from "./axiosForFormBuilder";

export const UseGet: any = (url: string) => {
 const [data, setData] = useState<any>(null);
 const [loading, setLoading] = useState<boolean>(true);
 const [error, setError] = useState<boolean>(false);

 useEffect(() => {
  const fetchData = async () => {
   try {
    const res = await axiosForFormBuilder.get(`${url}`);
    setData(res.data);
   } catch {
    setError(true);
   }
   setLoading(false);
  };
  fetchData();
 }, [url]);
 return { data, loading, error };
};
