import { useEffect } from "react";
import { Kapsul } from "../../assets/icons";

export const Feedback = () => {
 useEffect(() => {
  setTimeout(() => {
   window.location.href = "https://kapsul.org.tr";
  }, 5000);
 }, []);

 return (
  <div className="w-full min-h-[90vh] flex flex-col items-center justify-center gap-y-[10vh]">
   <Kapsul className="size-20 fill-white" />
   <h1 className="text-center  text-white  text-4xl font-bold">
    Kapsül Teknoloji Platformu
   </h1>
   <h1 className=" text-center text-xl text-white">
    Formunuz başarıyla kaydedilmiştir.
   </h1>
  </div>
 );
};
