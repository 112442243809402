import { ReactNode } from "react";

export const Box = ({
 children,
 className,
}: {
 children: ReactNode;
 className?: string;
}) => (
 <div
  className={`${className} m-0 box-border w-full  right-border   rounded  shadow-xl`}
 >
  <div className="bottom-border form-box-bg box-border bg-gradient-to-tl from-[#d3d2d24e] from-1% ...  h-full p-5">
   {children}
  </div>
 </div>
);
