import { axiosForFormBuilder } from ".";
import { FormType } from "../models/form.data.type";

export const createForm = async (form: FormType) => {
 try {
  const res = await axiosForFormBuilder.post("/forms/create", form);
  if (res.data.result) return res.data.result;
  return false;
 } catch (error) {
  console.error(error);
  return false;
 }
};
