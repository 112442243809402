import { Typography } from "antd";
import { useForm } from "../contexts";
import { FormComponentType } from "../models/form.data.type";
import {
 Box,
 FormComponentSettings,
 FormDatePicker,
 FormInput,
 FormMulti,
} from ".";

export const FormComponent = ({
 component,
 index,
}: {
 component: FormComponentType;
 index: number;
}) => {
 const { updateFormComponentTitleAndDescription, editMode } = useForm();

 const handleTitleChange = (value: string) => {
  updateFormComponentTitleAndDescription(
   { ...component, title: value, description: component.description || "" },
   index
  );
 };

 const handleDescriptionChange = (value: string) => {
  updateFormComponentTitleAndDescription(
   { ...component, title: component.title, description: value },
   index
  );
 };

 let Component;
 switch (component.type) {
  case "text":
   Component = <FormInput component={component} index={index} type="" />;
   break;

  case "email":
   Component = <FormInput component={component} index={index} type="email" />;
   break;

  case "phone":
   Component = <FormInput component={component} index={index} type="phone" />;
   break;

  case "ssn":
   Component = <FormInput component={component} index={index} type="ssn" />;
   break;

  case "select":
   Component = <FormMulti component={component} index={index} />;
   break;

  case "radio":
   Component = <FormMulti component={component} index={index} />;
   break;

  case "checkbox":
   Component = <FormMulti component={component} index={index} />;
   break;

  case "datetime":
   Component = <FormDatePicker component={component} index={index} />;
   break;

  default:
   break;
 }

 return (
  <Box>
   <div
    className={`flex md:flex-row flex-col w-full md:items-end items-start  ${
     component.type === "title" && !editMode
      ? "-mb-2 justify-center"
      : component.type === "description"
      ? "justify-end"
      : "justify-between"
    }`}
   >
    {(component.title || editMode) && component.type !== "description" && (
     <Typography.Title
      style={{ color: "white" }}
      className="m-0 p-0 "
      // align={component.type === "title" ? "center" : "left"}
      level={4}
      editable={
       editMode && {
        onChange: (value) => {
         handleTitleChange(value.length ? value : "Başlıksız Soru");
        },
       }
      }
     >
      {component.required ? "*" : ""} {component.title}
     </Typography.Title>
    )}
    {editMode && <FormComponentSettings component={component} index={index} />}
   </div>

   {Component}

   {(component.description || editMode) && component.type !== "title" && (
    <div className={component.type !== "description" ? "mt-2" : ""}>
     <Typography.Text
      style={{ color: "white" }}
      className="m-0 p-0"
      // align="left"
      // level={2}
      editable={
       editMode && {
        onChange: handleDescriptionChange,
       }
      }
     >
      {component.description}
     </Typography.Text>
    </div>
   )}
  </Box>
 );
};
