import { axiosForFormBuilder } from ".";

export const deleteForm = async (name: string) => {
 try {
  const res = await axiosForFormBuilder.delete(`/forms/${name}`);
  if (res.data.result) return true;
  return false;
 } catch (error) {
  console.error(error);
  return false;
 }
};
