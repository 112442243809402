import * as Antd from "antd";
import { UseGet } from "../../../utils";
import { SearchOutlined } from "@ant-design/icons";
import { useRef, useState } from "react";
import * as Type from "../../../models/form.data.type";
import type { FilterDropdownProps } from "antd/es/table/interface";
import {
 ColumnsManipulations,
 DataManipulations,
} from "../helper/TableManipulations";
import { useNavigate } from "react-router-dom";

type DataIndex = keyof Type.TableDataType;

export const ResponsesTableContainers = ({ form }: { form: Type.FormType }) => {
 const navigate = useNavigate();
 const { data, loading, error } = UseGet(`/forms/${form._id}/responses`);

 const [searchText, setSearchText] = useState("");
 const [searchedColumn, setSearchedColumn] = useState<string | number>("");
 const searchInput = useRef<Antd.InputRef>(null);

 const handleSearch = (
  selectedKeys: string[],
  confirm: FilterDropdownProps["confirm"],
  dataIndex: DataIndex
 ) => {
  confirm();
  setSearchText(selectedKeys[0]);
  setSearchedColumn(dataIndex);
 };

 const handleReset = (clearFilters: () => void) => {
  clearFilters();
  setSearchText("");
 };

 const getColumnSearchProps = (
  dataIndex: DataIndex
 ): Antd.TableColumnType<Type.TableDataType> => ({
  filterDropdown: ({
   setSelectedKeys,
   selectedKeys,
   confirm,
   clearFilters,
   close,
  }) => (
   <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
    <Antd.Input
     ref={searchInput}
     placeholder={`Search ${dataIndex}`}
     value={selectedKeys[0]}
     onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
     onPressEnter={() =>
      handleSearch(selectedKeys as string[], confirm, dataIndex)
     }
     style={{ marginBottom: 8, display: "block" }}
    />
    <Antd.Space>
     <Antd.Button
      type="primary"
      onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
      icon={<SearchOutlined />}
      size="small"
      style={{ width: 90 }}
     >
      Search
     </Antd.Button>
     <Antd.Button
      onClick={() => clearFilters && handleReset(clearFilters)}
      size="small"
      style={{ width: 90 }}
     >
      Reset
     </Antd.Button>
     <Antd.Button
      type="link"
      size="small"
      onClick={() => {
       confirm({ closeDropdown: false });
       setSearchText((selectedKeys as string[])[0]);
       setSearchedColumn(dataIndex);
      }}
     >
      Filter
     </Antd.Button>
     <Antd.Button
      type="link"
      size="small"
      onClick={() => {
       close();
      }}
     >
      close
     </Antd.Button>
    </Antd.Space>
   </div>
  ),
  filterIcon: (filtered: boolean) => (
   <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
  ),
  onFilter: (value, record) =>
   record[dataIndex]
    .toString()
    .toLowerCase()
    .includes((value as string).toLowerCase()),
  onFilterDropdownOpenChange: (visible) => {
   if (visible) {
    setTimeout(() => searchInput.current?.select(), 100);
   }
  },
  render: (text) =>
   searchedColumn === dataIndex ? (
    // <Highlighter
    //  highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
    //  searchWords={[searchText]}
    //  autoEscape
    //  textToHighlight={text ? text.toString() : ""}
    // />
    <strong>{text}</strong>
   ) : (
    text
   ),
 });

 return (
  <div className="w-screen mx-auto px-4 overflow-x-scroll">
   {loading ? (
    "Yükleniyor"
   ) : error ? (
    "Aradığınız form veya yanıt bulunamadı."
   ) : (
    <Antd.ConfigProvider
     theme={{
      components: {
       Table: {
        headerBg: "#F5F4F230",
        colorIcon: "#fff",
        colorIconHover: "#ffffffee",
        rowHoverBg: "#F5F4F210",
       },
       Button: {
        // colorPrimaryBg: "#F5F4F2",
        colorPrimary: "#000",
        colorPrimaryHover: "#00000099",
       },
      },
      token: {
       colorText: "#fff",
       boxShadowSecondary: "#ffffff00",
       colorBgContainer: "#161719",
       colorPrimary: "#F5F4F299",
      },
     }}
    >
     <Antd.Table
      columns={ColumnsManipulations(form, (name: string) => {
       return getColumnSearchProps(name);
      })}
      dataSource={DataManipulations(
       data,
       (to: string, response: Type.FormResponseType) => {
        navigate(to, { state: response });
       }
      ).reverse()}
      pagination={{ position: ["topRight"] }}
      style={{ overflow: "scroll" }}
      rootClassName="overflow-scroll"
      tableLayout="fixed"
      scroll={{ x: 3500, y: "75vh" }}
      title={() => {
       return (
        <h2 className="">Toplam cevap sayısı : {tempResponses.length}</h2>
       );
      }}
     />
    </Antd.ConfigProvider>
   )}
  </div>
 );
};

const tempForm: Type.FormType = {
 _id: "664dc8c75f9e1c7b67c2bba8",
 link: "kapsul-teknoloji-platformu-is-basvuru-formu",
 name: "Kapsül Teknoloji Platformu İş Başvuru Formu",
 description:
  "Lütfen formu eksiksiz ve doğru şekilde doldurunuz. Bu bilgiler, başvurunuzu değerlendirmemize yardımcı olacaktır. Teşekkür ederiz.",
 components: [
  {
   title: "İsim",
   type: "text",
   required: true,
   format: "short",
   options: [],
   _id: "664dc8c75f9e1c7b67c2bba9",
  },
  {
   title: "Soyisim",
   type: "text",
   required: true,
   format: "short",
   options: [],
   _id: "664dc8c75f9e1c7b67c2bbaa",
  },
  {
   title: "Doğum Tarihi",
   type: "datetime",
   required: true,
   format: "date",
   mode: "single",
   options: [],
   _id: "664dc8c75f9e1c7b67c2bbab",
  },
  {
   title: "Cinsiyet",
   type: "radio",
   required: true,
   options: ["Erkek", "Kadın"],
   _id: "664dc8c75f9e1c7b67c2bbac",
  },
  {
   title: "Telefon Numarası",
   type: "text",
   description: "Başında sıfır olmadan giriniz.",
   required: true,
   format: "short",
   options: [],
   _id: "664dc8c75f9e1c7b67c2bbad",
  },
  {
   title: "Mail Adresi",
   type: "text",
   description:
    "Mail adresinizi yazarken lütfen karakterler arasında boşluk bırakmayınız.",
   required: true,
   format: "short",
   options: [],
   _id: "664dc8c75f9e1c7b67c2bbae",
  },
  {
   title: "Adres",
   type: "text",
   required: true,
   format: "long",
   options: [],
   _id: "664dc8c75f9e1c7b67c2bbaf",
  },
  {
   title: "Uyruk",
   type: "text",
   description: "Örneğin: Türk, English...",
   required: true,
   format: "short",
   options: [],
   _id: "664dc8c75f9e1c7b67c2bbb0",
  },
  {
   title: "Eğitim Durumunuz",
   type: "radio",
   description: "Eğitim durumunuzu işaretleyiniz.",
   required: true,
   options: ["Öğrenci", "Mezun"],
   _id: "664dc8c75f9e1c7b67c2bbb1",
  },
  {
   title: "Son Mezuniyet Dereceniz",
   type: "radio",
   description: "Son mezuniyet derecenizi işaretleyiniz.",
   required: true,
   options: ["Lise", "Lisans", "Yüksek Lisans", "Doktora"],
   _id: "664dc8c75f9e1c7b67c2bbb2",
  },
  {
   title: "Okul Bilgisi",
   type: "text",
   description:
    "Eğer mezunsanız son mezun olduğunuz okulu, öğrenciyseniz aktif olarak öğrenim gördüğünüz okulu yazınız.",
   required: true,
   format: "long",
   options: [],
   _id: "664dc8c75f9e1c7b67c2bbb3",
  },
  {
   title: "Bölüm Bilgisi",
   type: "text",
   description:
    "Eğer mezunsanız son mezun olduğunuz bölümü, öğrenciyseniz aktif olarak öğrenim gördüğünüz bölümü ve sınıfı yazınız",
   required: true,
   format: "long",
   options: [],
   _id: "664dc8c75f9e1c7b67c2bbb4",
  },
  {
   title: "Şu Anki İstihdam Durumu",
   type: "radio",
   description: "Şu anki istihdam durumunuzu işaretleyiniz.",
   required: true,
   options: ["Çalışan(Kamu)", "Çalışan(Özel Sektör)", "Çalışmayan", "Öğrenci"],
   _id: "664dc8c75f9e1c7b67c2bbb5",
  },
  {
   title: "Çalıştığınız Kurum ve Pozisyon",
   type: "text",
   description: "Son çalıştığınız kurum ve pozisyonu yazınız.",
   required: true,
   format: "long",
   options: [],
   _id: "664dc8c75f9e1c7b67c2bbb6",
  },
  {
   title: "Hangi Departmana Başvurmak İstiyorsunuz?",
   type: "checkbox",
   description: "Hangi departmana başvurmak istediğinizi işaretleyiniz.",
   required: true,
   options: [
    "Milli Teknoloji Hamlesi",
    "Borsa İstanbul Lab.",
    "İnsan Kaynakları",
    "Girişimcilik",
    "Engin Hub",
    "Akıllı Şehirler",
    "Akademi",
    "Kurumsal İletişim",
    "Proje",
    "Yazılım",
    "Diğer",
   ],
   _id: "664dc8c75f9e1c7b67c2bbb7",
  },
  {
   title: "Referanslar",
   type: "text",
   description:
    "Referanslarınızı çalıştığı kurum ve telefon numaraları ile birlikte yazınız.",
   required: false,
   format: "long",
   options: [],
   _id: "664dc8c75f9e1c7b67c2bbb8",
  },
 ],
 isPublished: true,
 isDeleted: false,
 deletedAt: null,
 createdAt: "2024-05-22T10:28:23.931Z",
 updatedAt: "2024-05-22T10:28:23.931Z",
 __v: 0,
};

const tempResponses: Type.FormResponseType[] = [
 {
  _id: "664dd3545f9e1c7b67c2cb4d",
  formId: "664dc8c75f9e1c7b67c2bba8",
  answers: [
   {
    questionId: "664dc8c75f9e1c7b67c2bba9",
    value: "Test isim",
    _id: "664dd3545f9e1c7b67c2cb4e",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbaa",
    value: "Test soyisim",
    _id: "664dd3545f9e1c7b67c2cb4f",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbab",
    value: "2024-05-22T11:12:19.834Z",
    _id: "664dd3545f9e1c7b67c2cb50",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbac",
    value: "Erkek",
    _id: "664dd3545f9e1c7b67c2cb51",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbad",
    value: "111111111111",
    _id: "664dd3545f9e1c7b67c2cb52",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbae",
    value: "test@test.com",
    _id: "664dd3545f9e1c7b67c2cb53",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbaf",
    value: "test adres",
    _id: "664dd3545f9e1c7b67c2cb54",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb0",
    value: "test uyruk",
    _id: "664dd3545f9e1c7b67c2cb55",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb1",
    value: "Öğrenci",
    _id: "664dd3545f9e1c7b67c2cb56",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb2",
    value: "Lise",
    _id: "664dd3545f9e1c7b67c2cb57",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb3",
    value: "test okul bilgisi",
    _id: "664dd3545f9e1c7b67c2cb58",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb4",
    value: "test bölüm bilgisi",
    _id: "664dd3545f9e1c7b67c2cb59",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb5",
    value: "Çalışan(Kamu)",
    _id: "664dd3545f9e1c7b67c2cb5a",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb6",
    value: "test pozisyon",
    _id: "664dd3545f9e1c7b67c2cb5b",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb7",
    value: ["Milli Teknoloji Hamlesi"],
    _id: "664dd3545f9e1c7b67c2cb5c",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb8",
    value: "test referans",
    _id: "664dd3545f9e1c7b67c2cb5d",
   },
  ],
  isDeleted: false,
  deletedAt: null,
  createdAt: "2024-05-22T11:13:24.114Z",
  updatedAt: "2024-05-22T11:13:24.114Z",
  __v: 0,
 },
 {
  _id: "6656ef405f9e1c7b67c2d427",
  formId: "664dc8c75f9e1c7b67c2bba8",
  answers: [
   {
    questionId: "664dc8c75f9e1c7b67c2bba9",
    value: "Fadime",
    _id: "6656ef405f9e1c7b67c2d428",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbaa",
    value: "Coşkuner",
    _id: "6656ef405f9e1c7b67c2d429",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbab",
    value: "2001-01-15T10:00:08.819Z",
    _id: "6656ef405f9e1c7b67c2d42a",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbac",
    value: "Kadın",
    _id: "6656ef405f9e1c7b67c2d42b",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbad",
    value: "5319817848",
    _id: "6656ef405f9e1c7b67c2d42c",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbae",
    value: "fadimeecoskuner@gmail.com",
    _id: "6656ef405f9e1c7b67c2d42d",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbaf",
    value: "Karatay/Konya",
    _id: "6656ef405f9e1c7b67c2d42e",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb0",
    value: "Türk",
    _id: "6656ef405f9e1c7b67c2d42f",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb1",
    value: "Mezun",
    _id: "6656ef405f9e1c7b67c2d430",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb2",
    value: "Lisans",
    _id: "6656ef405f9e1c7b67c2d431",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb3",
    value: "Necmettin Erbakan Üniversitesi",
    _id: "6656ef405f9e1c7b67c2d432",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb4",
    value: "Bilgisayar Mühendisliği",
    _id: "6656ef405f9e1c7b67c2d433",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb5",
    value: "Çalışan(Kamu)",
    _id: "6656ef405f9e1c7b67c2d434",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb6",
    value: "Kapsül Teknoloji Platformu | Araştırmacı",
    _id: "6656ef405f9e1c7b67c2d435",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb7",
    value: ["Yazılım"],
    _id: "6656ef405f9e1c7b67c2d436",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb8",
    value: "Şeyda Arslan",
    _id: "6656ef405f9e1c7b67c2d437",
   },
  ],
  isDeleted: false,
  deletedAt: null,
  createdAt: "2024-05-29T09:02:56.358Z",
  updatedAt: "2024-05-29T09:02:56.358Z",
  __v: 0,
 },
 {
  _id: "668541c7b88f8e02ca5125e2",
  formId: "664dc8c75f9e1c7b67c2bba8",
  answers: [
   {
    questionId: "664dc8c75f9e1c7b67c2bba9",
    value: "Meryem Melek ",
    _id: "668541c7b88f8e02ca5125e3",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbaa",
    value: "Şahin ",
    _id: "668541c7b88f8e02ca5125e4",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbab",
    value: "2000-07-12T12:16:14.846Z",
    _id: "668541c7b88f8e02ca5125e5",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbac",
    value: "Kadın",
    _id: "668541c7b88f8e02ca5125e6",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbad",
    value: "5075740238",
    _id: "668541c7b88f8e02ca5125e7",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbae",
    value: "meryemsahin119@gmail.com",
    _id: "668541c7b88f8e02ca5125e8",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbaf",
    value: "Selçuklu/Konya",
    _id: "668541c7b88f8e02ca5125e9",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb0",
    value: "Türk ",
    _id: "668541c7b88f8e02ca5125ea",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb1",
    value: "Öğrenci",
    _id: "668541c7b88f8e02ca5125eb",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb2",
    value: "Lisans",
    _id: "668541c7b88f8e02ca5125ec",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb3",
    value:
     "Necmettin Erbakan Üniversitesi \nFen Bilimleri Enstitüsü \nŞehir ve Bölge Planlama/Tezli",
    _id: "668541c7b88f8e02ca5125ed",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb4",
    value: "Şehir ve Bölge Planlama ",
    _id: "668541c7b88f8e02ca5125ee",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb5",
    value: "Öğrenci",
    _id: "668541c7b88f8e02ca5125ef",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb6",
    value: "Yılmazkent Planlama Müh. Mim.",
    _id: "668541c7b88f8e02ca5125f0",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb7",
    value: ["Girişimcilik", "Akıllı Şehirler", "Akademi", "Proje"],
    _id: "668541c7b88f8e02ca5125f1",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb8",
    value: "",
    _id: "668541c7b88f8e02ca5125f2",
   },
  ],
  isDeleted: false,
  deletedAt: null,
  createdAt: "2024-07-03T12:19:19.946Z",
  updatedAt: "2024-07-03T12:19:19.946Z",
  __v: 0,
 },
 {
  _id: "668f9febb88f8e02ca51274a",
  formId: "664dc8c75f9e1c7b67c2bba8",
  answers: [
   {
    questionId: "664dc8c75f9e1c7b67c2bba9",
    value: "Onur",
    _id: "668f9febb88f8e02ca51274b",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbaa",
    value: "Avan",
    _id: "668f9febb88f8e02ca51274c",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbab",
    value: "1997-11-19T10:01:35.342Z",
    _id: "668f9febb88f8e02ca51274d",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbac",
    value: "Erkek",
    _id: "668f9febb88f8e02ca51274e",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbad",
    value: "5510464031",
    _id: "668f9febb88f8e02ca51274f",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbae",
    value: "avanonur@hotmail.com",
    _id: "668f9febb88f8e02ca512750",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbaf",
    value: "Alpaslan Mah. Koçbeyaz Cad. No:73 Meram/Konya",
    _id: "668f9febb88f8e02ca512751",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb0",
    value: "Türk",
    _id: "668f9febb88f8e02ca512752",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb1",
    value: "Mezun",
    _id: "668f9febb88f8e02ca512753",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb2",
    value: "Lisans",
    _id: "668f9febb88f8e02ca512754",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb3",
    value: "Necmettin Erbakan Üniversitesi",
    _id: "668f9febb88f8e02ca512755",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb4",
    value: "Tarih",
    _id: "668f9febb88f8e02ca512756",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb5",
    value: "Çalışmayan",
    _id: "668f9febb88f8e02ca512757",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb6",
    value:
     "Venüs Yazılım Elektronik Güvenlik Sistemleri/ Teknik Servis Elemanı",
    _id: "668f9febb88f8e02ca512758",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb7",
    value: ["Yazılım"],
    _id: "668f9febb88f8e02ca512759",
   },
   {
    questionId: "664dc8c75f9e1c7b67c2bbb8",
    value: "Referansım yoktur.",
    _id: "668f9febb88f8e02ca51275a",
   },
  ],
  isDeleted: false,
  deletedAt: null,
  createdAt: "2024-07-11T09:03:39.983Z",
  updatedAt: "2024-07-11T09:03:39.983Z",
  __v: 0,
 },
];

// const columns: Antd.TableColumnsType<Type.TableDataType> = [
//   {
//    title: "Name",
//    dataIndex: "name",
//    key: "name",
//    ...getColumnSearchProps("name"),
//   },
//   {
//    title: "Age",
//    dataIndex: "age",
//    key: "age",
//    ...getColumnSearchProps("age"),
//   },
//   {
//    title: "Address",
//    dataIndex: "address",
//    key: "address",

//    ...getColumnSearchProps("address"),
//    sorter: (a, b) => a.address.length - b.address.length,
//    sortDirections: ["descend", "ascend"],
//   },
//  ];
