import { Logo } from "../../sharedComponent";

export const Navbar = () => {
 return (
  <>
   <div className={`w-full duration-300 py-4  `}>
    <div className="w-[90vw] md:w-[95vw] mx-auto flex justify-between items-center">
     <Logo className="size-[35px] fill-white hover:scale-105 duration-300" />
     <div className="flex items-center gap-x-2 md:gap-x-3"></div>
    </div>
   </div>
  </>
 );
};
