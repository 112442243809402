import {
 useState,
 useEffect,
 ReactNode,
 useContext,
 createContext,
} from "react";
import { AuthContextType } from "./AuthContextType";
import { Check } from "../utils/authService";

interface AuthProviderProps {
 children: ReactNode;
}

const AuthContext = createContext<AuthContextType>({
 authLoading: true,
 setAuthLoading: () => {},
 authError: false,
 setAuthError: () => {},
});

export const AuthContextProvider: React.FC<AuthProviderProps> = ({
 children,
}) => {
 const [authLoading, setAuthLoading] = useState<boolean>(true);
 const [authError, setAuthError] = useState<boolean>(false);

 const AuthCheck = async () => {
  if (!(await Check())) setAuthError(true);

  setAuthLoading(false);
 };

 useEffect(() => {
  AuthCheck();
 }, []);

 return (
  <AuthContext.Provider
   value={{ authLoading, setAuthLoading, authError, setAuthError }}
  >
   {children}
  </AuthContext.Provider>
 );
};

export const useAuth = () => {
 return useContext(AuthContext);
};
