export const arrayMove = (array: any[], oldIndex: number, newIndex: number) => {
 while (oldIndex < 0) {
  oldIndex += array.length;
 }

 while (newIndex < 0) {
  newIndex += array.length;
 }

 if (newIndex >= array.length) {
  let k = newIndex - array.length + 1;
  while (k--) {
   array.push(undefined);
  }
 }

 array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);

 return array;
};

// arrayMove([1, 2, 3], -1, -2))
// returns [1, 3, 2]
