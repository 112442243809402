import * as Pages from "../pages";

export interface routerType {
 path: string;
 component: any;
}

export const authPageDatas: routerType[] = [
 {
  path: "/",
  component: <Pages.Home />,
 },
 {
  path: "/response",
  component: <Pages.FormResponse />,
 },
 {
  path: "/create",
  component: <Pages.Create />,
 },
 {
  path: "/print",
  component: <Pages.Print />,
 },
];

export const publicPageDatas: routerType[] = [
 {
  path: "/submit",
  component: <Pages.FormSubmit />,
 },
 {
  path: "/feedback",
  component: <Pages.Feedback />,
 },
];