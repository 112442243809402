import { Button } from "antd";
import {
 FormType,
 FormAnswer,
 TableDataType,
 TableColumnData,
 FormResponseType,
 FormComponentType,
} from "../../../models/form.data.type";

export const DataManipulations = (
 responses: FormResponseType[],
 navigate: any
) => {
 let _responses: TableDataType[] = [];

 responses.forEach((response: FormResponseType) => {
  let _answer = {};

  response.answers.forEach((answer: FormAnswer) => {
   if (Array.isArray(answer.value))
    _answer = { ..._answer, [answer.questionId]: answer.value.join(", ") };
   else _answer = { ..._answer, [answer.questionId]: answer.value };
  });

  //   _answer = {
  //    ..._answer,
  //    cv: (
  //     <Button
  //      onClick={() => {
  //       navigate("/print", { state: response });
  //      }}
  //      className="text-white"
  //     >
  //      CV gör
  //     </Button>
  //    ),
  //   };

  _responses.push(_answer);
 });
 return _responses;
};

export const ColumnsManipulations = (
 form: FormType,
 filterProps: (name: string) => any
) => {
 let _columns: TableColumnData[] = [];

 form.components.forEach((component: FormComponentType) => {
  _columns.push({
   dataIndex: component._id || "",
   key: component._id || "",
   title: component.title,
   ...filterProps(component._id || ""),
   //    sortDirections: ["descend", "ascend"],
   //    defaultSortOrder: "descend",
   //    sorter: (a: any, b: any) => a[component._id || ""] - b[component._id || ""],
  });
 });

 //  _columns.push({
 //   dataIndex: "cv",
 //   key: "cv",
 //   title: "CV Formatı",
 //  });

 return _columns;
};
