import { Box } from "./Box";
import { useForm } from "../contexts";
import { Kapsul } from "../assets/icons";
import { ConfigProvider, Typography } from "antd";

export const FormHeader = () => {
 const { form, updateFormName, updateFormDescription, editMode } = useForm();

 return (
  <ConfigProvider
   theme={{
    token: {
     colorText: "white",
     colorBgBase: "transparent",
    },
   }}
  >
   <Box>
    <div className="flex h-full w-full flex-col items-center gap-y-2 justify-center !text-white">
     <Kapsul className="fill-white size-10" />
     <Typography.Title editable={editMode && { onChange: updateFormName }}>
      {form.name}
     </Typography.Title>
     {form.description && (
      <Typography.Text
       editable={editMode && { onChange: updateFormDescription }}
      >
       {form.description}
      </Typography.Text>
     )}
    </div>
   </Box>
  </ConfigProvider>
 );
};
