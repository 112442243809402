import { useForm } from "../../contexts";
import { useEffect, useState } from "react";
import { FormComponentType } from "../../models/form.data.type";
import {
 Box,
 BoxContainer,
 FloatButtons,
 FormComponent,
 FormHeader,
} from "../../sharedComponent";
import { createForm } from "../../utils";
import { QuickQuestions } from "./components/QuickQuestions";
import { useNavigate } from "react-router-dom";

export const Create = () => {
 const navigate = useNavigate();
 const { form, setEditMode } = useForm();
 const [createMode, setCreateMode] = useState<boolean>(true);

 useEffect(() => {
  setEditMode(true);
  //   setCreateMode(Object.keys(form).length ? false : true);
  setCreateMode(true);
 }, [setEditMode]);

 const CreateForm = async () => {
  let result;

  if (!form.name.length && !form.description.length)
   alert("Formun ismini veya açıklamasını boş bırakamazsınız!");
  else {
   if (createMode) {
    result = await createForm(form);
   }
  }
  if (result) navigate("/");
  else alert("Bir hata meydana geldi!");
 };

 return (
  <>
   <FloatButtons />
   <div className="m-0 p-0 mb-10 w-[95vw] sm:w-[85vw] md:w-[60vw] mx-auto">
    <BoxContainer>
     <FormHeader />
     <QuickQuestions />
     {form.components.map((component: FormComponentType, index: number) => (
      <FormComponent component={component} index={index} key={index} />
     ))}
     <Box>
      <button
       onClick={CreateForm}
       className="h-[50px] w-[100%] text-[17px] bg-transparent border-none text-white hover:text-[#b1b0b0]   "
      >
       Kaydet
      </button>
     </Box>
    </BoxContainer>
   </div>
  </>
 );
};
