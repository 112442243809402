import { useLocation } from "react-router-dom";
import { ReadQuery } from "../../helper/SearchQuery";
import { FormSubmitContainers } from "./containers/FormSubmitContainers";
import { UseGet } from "../../utils";
import { useEffect } from "react";
import { useForm } from "../../contexts";

export const FormSubmit = () => {
 const query = ReadQuery(useLocation().search);
 const { loading, error, data } = UseGet(`/forms/${query.form}`);
 const { setForm } = useForm();
 useEffect(() => {
  if (!(loading | error)) setForm(data);
 }, [data]);

 return (
  <div className="w-[95vw] sm:w-[85vw] md:w-[60vw] mx-auto *:mx-auto">
   {loading ? (
    "Yükle"
   ) : error ? (
    "Aradığınız form bulunamadı."
   ) : (
    <FormSubmitContainers />
   )}
  </div>
 );
};
