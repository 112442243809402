import { MainLayout, UserLayout } from "../layouts";
import { Navigate, Route, Routes } from "react-router-dom";
import { authPageDatas, publicPageDatas, routerType } from "./router.data";

const Router = () => {
 const authPageRoutes = authPageDatas.map((page: routerType) => {
  return <Route key={page.path} path={page.path} element={page.component} />;
 });
 const publicPageRoutes = publicPageDatas.map((page: routerType) => {
  return <Route key={page.path} path={page.path} element={page.component} />;
 });

 return (
  <Routes>
   <Route path="" element={<MainLayout />} children={authPageRoutes} />
   <Route path="" element={<UserLayout />} children={publicPageRoutes} />
   <Route path="*" element={<Navigate to={"/"} />} />
  </Routes>
 );
};

export default Router;
