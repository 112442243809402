import { FloatButton } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { LogoutButton } from "./LogoutButton";

export const FloatButtons = () => {
 return (
  <>
   <LogoutButton />
   <a href="/">
    <FloatButton style={{ right: 50, bottom: 110 }} icon={<HomeOutlined />} />
   </a>
  </>
 );
};
