import { Kapsul } from "../assets/icons";

export const Loading = ({ description }: { description?: string }) => {
 return (
  <div className="h-[100vh] w-[100vw] fixed  inset-0 bg-ktp_black flex flex-col items-center gap-y-10 justify-center z-[1000]">
   <Kapsul className="w-12 h-12 fill-white animate-pulse" />
   <h1 className="text-white text-[24px] font-bold animate-pulse px-6 text-center">
    {description}
   </h1>
  </div>
 );
};
