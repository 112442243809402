export const XTwitter = (props) => (
 <svg
  stroke="currentColor"
  fill="currentColor"
  strokeWidth="0"
  viewBox="0 0 24 24"
  {...props}
  height="200px"
  width="200px"
  xmlns="http://www.w3.org/2000/svg"
 >
  <path d="M8 2H1L9.26086 13.0145L1.44995 21.9999H4.09998L10.4883 14.651L16 22H23L14.3917 10.5223L21.8001 2H19.1501L13.1643 8.88578L8 2ZM17 20L5 4H7L19 20H17Z"></path>
 </svg>
);
