import { FloatButton } from "antd";
import { FormContainers } from "./containers/FormContainers";
import { PlusOutlined } from "@ant-design/icons";
import { LogoutButton } from "../../sharedComponent";

export const Home = () => {
 return (
  <>
   <LogoutButton />
   <a href="/create">
    <FloatButton style={{ right: 50, bottom: 110 }} icon={<PlusOutlined />} />
   </a>
   <div className=" w-full flex flex-col items-center gap-y-10">
    <h1 className="font-bold text-[40px]">Kapsül Formlar</h1>
    <FormContainers />
   </div>
  </>
 );
};
