import { FormAnswersType, FormType } from "../../../models/form.data.type";

export const SubmitFormManipulation = (
 form: FormType,
 answers: { [key: string]: string }
) => {
 let data: FormAnswersType = { userId: form._id || "", answers: [] };

 form.components.forEach((component) => {
  if (component.type === "title") return;

  if (answers[component._id || ""])
   data.answers.push({
    questionId: component._id || "",
    value: answers[component._id || ""],
   });
 });

 return data;
};
