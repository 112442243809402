import { useForm } from "../../../contexts";
import { useNavigate } from "react-router-dom";
import { createResponse } from "../../../utils";
import { Box } from "../../../sharedComponent/Box";
import { Button, ConfigProvider, Form } from "antd";
import { FormType } from "../../../models/form.data.type";
import { BoxContainer } from "../../../sharedComponent/BoxContainer";
import { SubmitFormManipulation } from "../helper/SubmitFormManipulation";
import { FormHeader, SubmitFormComponent } from "../../../sharedComponent";

export const FormSubmitContainers = () => {
 const { form } = useForm();
 const navigate = useNavigate();

 const SubmitForm = async (
  form: FormType,
  answers: { [key: string]: string }
 ) => {
  let result = await createResponse(SubmitFormManipulation(form, answers));

  if (result) navigate("/feedback");
  else alert("Bir hata oluştu.");
 };

 return (
  <ConfigProvider
   theme={{
    components: {
     Form: {
      verticalLabelMargin: 4,
      colorBgBase: "#fff",
      labelColor: "#fff",
      labelFontSize: 20,
     },
     DatePicker: {
      colorBgBase: "#1677FF",
      cellActiveWithRangeBg: "#1677FF",
     },
    },
    token: {
     colorTextDescription: "rgba(255,255,255,0.7)",
     colorTextLightSolid: "rgba(0, 0, 0, 0.88)",
     //  colorIconPlaceholder: "rgb(255,255,255)",
     lineWidth: 1.5,
     //  selectorBg: "#0096fb",
    },
   }}
  >
   <Form
    scrollToFirstError={true}
    layout="vertical"
    onFinish={(e: { [key: string]: string }) => {
     SubmitForm(form, e);
    }}
    onFinishFailed={(e) => console.log("fail", e)}
   >
    <div className="m-0 p-0">
     <BoxContainer>
      <FormHeader />
      {form.components.map((component, index) => (
       <SubmitFormComponent component={component} index={index} key={index} />
      ))}
      <Box className="mb-4">
       <Form.Item>
        <Button
         size="large"
         className="bg-white w-full flex items-center justify-center mt-5 font-bold "
         htmlType="submit"
        >
         Kaydet
        </Button>
       </Form.Item>
      </Box>
     </BoxContainer>
    </div>
   </Form>
  </ConfigProvider>
 );
};
