import { Navbar } from "./navbar";
import { Footer } from "./footer";
import { Helmet } from "react-helmet";
import { useAuth } from "../contexts";
import { Outlet } from "react-router-dom";
import { Loading } from "../sharedComponent";

export const MainLayout = () => {
 const { authLoading, authError } = useAuth();

 return (
  <>
   <Helmet>
    <html className={`m-0 p-0 `} />
    <body className="min-h-screen  font-[montserrat] bg-ktp_black text-white duration-300 w-full m-0 overflow-x-hidden p-0  box-border   " />
   </Helmet>
   {authLoading ? (
    <Loading description="Yetkilendirme sistemine bağlanılıyor, lütfen bekleyiniz." />
   ) : authError ? (
    <Loading description="Yetkilendirme sistemine bağlanırken bir hata meydana geldi." />
   ) : (
    <>
     <Navbar />
     <div className=" min-h-screen w-full overflow-x-hidden py-10">
      <Outlet />
     </div>
     <Footer />
    </>
   )}
  </>
 );
};
