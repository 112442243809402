import { Navbar } from "./navbar";
import { Footer } from "./footer";
import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";

export const UserLayout = () => {
 return (
  <>
   <Helmet>
    <html className={`m-0 p-0 `} />
    <body className="min-h-screen  font-[montserrat] bg-ktp_black text-white duration-300 w-full m-0 overflow-x-hidden p-0  box-border   " />
   </Helmet>
   {/* <Navbar /> */}
   <div className=" min-h-screen w-full overflow-x-hidden py-10">
    <Outlet />
   </div>
   <Footer />
  </>
 );
};
